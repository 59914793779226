import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const PhoneInputComponent = ({ value, formikProps }) => {
    const handlePhoneChange = (value, country) => {
        const dialCode = `+${country.dialCode}`;
        const number = value.startsWith(dialCode)
            ? value.replace(dialCode, '').trim() // Remove country code
            : value.trim();

        // Minimum length validation for actual phone number (excluding country code)
        const minLength = 10; // Adjust this if needed for certain regions
        const isValidPhoneNumber = number.length >= minLength;

        console.log("Full Input:", value);
        console.log("Country:", country.name, "Dial Code:", dialCode);
        console.log("Extracted Phone Number:", number, "Valid:", isValidPhoneNumber);

        // Update formik fields
        formikProps.setFieldValue('phoneNumber', isValidPhoneNumber ? number : '');
        formikProps.setFieldValue('countryCode', dialCode);
    };

    return (
        <div className='w-full'>
            <PhoneInput
             // enableLongNumbers={true}
                defaultCountry="US"
                value={value}
                placeholder='Enter phone number'
                className={`mt-1 h-[55px] !w-full rounded-lg mb-4 text-[#6A7186] text-[16px] placeholder:text-[#6A7186] placeholder:font-normal`}
                onChange={handlePhoneChange}
                country={'us'}
                countryCodeEditable={true}
                enableAreaCodes={false} // Disable area codes
                disableCountryCode={false} // Allow country code to be displayed and editable
                inputStyle={{
                    width: '100%',
                    padding: '10px',
                    borderRadius: '4px',
                }}
            />
        </div>
    );
};

export default PhoneInputComponent;
